var p = 0,
    t = 1;
$(window).scroll(function (e) {
    if(document.getElementById('_noEffect')) return;
    p = $(this).scrollTop();
    if (p == 0) {
        $('.page-header').removeClass('active').removeClass('disabled')
    } else if (t >= 0 && p >= 0) {
        if (t <= p) {
            $('.page-header').removeClass('active').addClass('disabled')
        } else {
            if (p < 200) {
                $('.page-header').removeClass('active').removeClass('disabled')
            } else {
                $('.page-header').removeClass('disabled').addClass('active');
            }
        }
        setTimeout(function () {
            t = p;
        }, 0);
    }
});
$(document).ready(() => {
    var $downIcon = $('.down-icon')
    $('.down-icon').click(function () {

        $(this).next().toggle(200, function (e) {
            if (parseInt(this.clientHeight) > 20) {
                $(this).prev().parent().addClass('showList')
            } else {
                $(this).prev().parent().removeClass('showList')
            }
        })
    })
})
